import { selectIsStateLoading, selectResult, selectResultOrEmptyArray } from '@icp/angular/ngrx';
import { GetEconomyOverviewDto } from '@icp/interfaces';
import { createSelector } from '@ngrx/store';

import { selectActiveUser } from '../../auth/store/auth.selectors';
import { ViewState } from '../../core/model';
import { selectEconomiesList } from '../../pages/state/pages.feature';
import { createEconomyThemeConfig } from '../../shared/theming/utils';
import { sharedFeature } from './shared.feature';

export const {
    selectAvailablePlaceTypes,
    selectDashboardEconomy,
    selectDashboardEconomyId,
    selectAvailableRoles,
    selectCurrentLang,
    selectLegalForms,
} = sharedFeature;
export const selectDashboardEconomyResult = createSelector(selectDashboardEconomy, selectResult);
export const selectDashboardEconomyName = createSelector(selectDashboardEconomyResult, (economy) => economy?.name);
export const selectHasNoDashboardEconomy = createSelector(selectDashboardEconomyId, (economyId) => !economyId);
export const selectDashboardEconomyTheme = createSelector(selectDashboardEconomyResult, createEconomyThemeConfig);

export const selectDashboardEconomyLogoUrl = createSelector(
    selectDashboardEconomyResult,
    (economy) => economy?.logo?.url,
);

export const selectDashboardEconomyCoins = createSelector(
    selectDashboardEconomyResult,
    (economy) => economy?.coins ?? [],
);

export const selectEconomyOrGlobalCoins = createSelector(
    selectEconomiesList,
    selectDashboardEconomyResult,
    (economies, dashboardEconomy) => dashboardEconomy?.coins ?? economies.flatMap((e) => e.coins),
);
export const selectCurrentOrAllEconomies = createSelector(
    selectEconomiesList,
    selectDashboardEconomyResult,
    (economies, dashboardEconomy): GetEconomyOverviewDto[] => (dashboardEconomy ? [dashboardEconomy] : economies),
);

export const selectEconomyOrGlobalBaseCurrencies = createSelector(selectEconomyOrGlobalCoins, (coins) => [
    ...new Map(coins?.map((coin) => [coin.baseCurrency.id, coin.baseCurrency])).values(),
]);

export const selectViewState = createSelector(selectDashboardEconomyId, (id) =>
    id ? ViewState.ECONOMY_VIEW : ViewState.GLOBAL_VIEW,
);
export const selectDashboardEconomyLoading = createSelector(selectDashboardEconomy, selectIsStateLoading);

export const selectAvailableRolesLoading = createSelector(selectAvailableRoles, selectIsStateLoading);
export const selectAvailableRolesResult = createSelector(selectAvailableRoles, selectResult);

/**
 * All base currencies in ICP. Should only be used on screens where no economy is selected, or when creating a new coin.
 */
export const selectAllBaseCurrencies = createSelector(sharedFeature.selectBaseCurrencies, selectResultOrEmptyArray);

export const selectEconomyRoles = createSelector(selectAvailableRolesResult, (roles) => roles?.economy ?? []);
export const selectBusinessRoles = createSelector(selectAvailableRolesResult, (roles) => roles?.business ?? []);
export const selectStoreRoles = createSelector(selectAvailableRolesResult, (roles) => roles?.store ?? []);

export const selectUserAndEconomy = createSelector(selectDashboardEconomyResult, selectActiveUser, (economy, user) => ({
    economy,
    user,
}));

export const selectAvailablePlaceTypesList = createSelector(selectAvailablePlaceTypes, selectResultOrEmptyArray);
export const selectAvailablePlaceTypesMap = createSelector(
    selectAvailablePlaceTypesList,
    (placeTypes) => new Map(placeTypes.map((pt) => [pt.id, pt])),
);
export const selectAvailablePlaceTypesLoading = createSelector(selectAvailablePlaceTypes, selectIsStateLoading);

export const selectLegalFormsLoading = createSelector(selectLegalForms, selectIsStateLoading);
export const selectLegalFormsList = createSelector(selectLegalForms, selectResultOrEmptyArray);
